import axios, { AxiosInstance } from 'axios'
import interseptor from './auth.interseptor'
import QS from 'qs'
import { ReactText } from 'react'
import { ISpetification } from '../redux/actions/projectsAction/types'
import { toast } from 'react-toastify'

const domain = (url: string) =>
	url === '/authorize' || url === '/auth/logout'
		? 'https://dev.auth.gi-pro.ru/api/v1'
		: 'http://dev.ncs.gi-pro.ru/ptk/v2'

const modeURL = process.env.NODE_ENV === 'development' ? `//localhost:4000/` : `https://dev.auth.gi-pro.ru/`
const LOCATION_GIPRO = 'https://gi-pro.ru/'

// Дефолтные заголовки с авторизацией
const fetchHeaders = () => ({
	'X-Requested-With': 'XMLHttpRequest',
	'Content-Type': 'application/json',
	Authorization: `Bearer ${localStorage.getItem('access_token')}`,
})

const authApp: AxiosInstance = axios.create({
	headers: fetchHeaders(),
	baseURL: 'https://dev.auth.gi-pro.ru/api/v1',
	withCredentials: true,
})

const ncsApp: AxiosInstance = axios.create({
	headers: fetchHeaders(),
	baseURL: 'https://dev.ncs.gi-pro.ru/api',
	paramsSerializer: params => {
		return QS.stringify(params, { arrayFormat: 'comma' })
	},
})

interseptor(ncsApp)

// Функция делает запрос по указанному url,
// callback - функция, которая возвращает ответ к нужному нам виду

const authorizeApi = () => {
	const data = {
		response_type: 'token',
		client_id: 'ncs',
		with: 'user',
	}
	return authApp({
		method: 'POST',
		url: '/authorize',
		data: JSON.stringify(data),
	})
		.then((res: any) => res.data)
		.catch((error: any) => {
			console.log(error)
			localStorage.removeItem('access_token')
			localStorage.removeItem('user')
			window.location.href = modeURL
		})
}

const logoutApi = () => {
	return authApp({
		method: 'POST',
		url: '/auth/logout',
	}).finally(() => {
		localStorage.removeItem('access_token')
		localStorage.removeItem('user')
		window.location.href = modeURL
	})
}

const getProjectsApi = (params: any) =>
	ncsApp({
		method: 'GET',
		url: '/projects',
		params,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const createProjectApi = (data: any) =>
	ncsApp({
		method: 'POST',
		url: '/projects',
		data: JSON.stringify(data),
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const updateProjectApi = (data: any, id: ReactText) =>
	ncsApp({
		method: 'PUT',
		url: `/projects/${id}`,
		data: JSON.stringify(data),
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const updateFilesToProjectApi = (data: any, id: ReactText) =>
	ncsApp({
		method: 'POST',
		url: `/projects/${id}/files`,
		data,
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${localStorage.getItem('access_token')}`,
		},
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const selectProjectApi = (id: number) =>
	ncsApp({
		method: 'GET',
		url: `/projects/${id}`,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const deleteProjectApi = (id: number) =>
	ncsApp({
		method: 'DELETE',
		url: `/projects/${id}`,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const deleteFilesApi = (id: number) =>
	ncsApp({
		method: 'DELETE',
		url: `/files/${id}`,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const searchProjectsApi = (params: any) =>
	ncsApp({
		method: 'GET',
		url: `/projects`,
		params,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const getOptionsApi = () =>
	ncsApp({
		method: 'GET',
		url: `/input-options`,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const getSpecificationsApi = (id: number) =>
	ncsApp({
		method: 'GET',
		url: `/projects/${id}/specifications`,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const downloadFileApi = (fileId: number, name: string) => {
	return ncsApp({
		method: 'GET',
		url: `/files/${fileId}`,
		responseType: 'blob',
	})
		.then((res: any) => {
			const url = URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')

			document.body.appendChild(link)
			link.href = url
			link.download = `${name}.xlsx`
			link.click()
			link.remove()
		})
		.catch((error: any) => error)
}

const getRmApi = (id: number, code: string) => {
	return ncsApp({
		method: 'GET',
		url: `/projects/${id}/rm`,
	})
		.then((res: any) => {
			const { url, errors } = res.data.data

			if (url) {
				ncsApp({
					method: 'GET',
					url,
					responseType: 'blob',
				})
					.then((res: any) => {
						const url = URL.createObjectURL(new Blob([res.data]))
						const link = document.createElement('a')

						document.body.appendChild(link)
						link.href = url
						link.download = `РМ - Проект ${code}.xlsx`
						link.click()
						link.remove()
					})
					.catch((error: any) => error)
			}

			if (errors?.length) {
				errors?.forEach((error: any) => {
					Object?.values(error)?.forEach((value: any) => {
						toast.error(value, { autoClose: false })
					})
				})
			}
		})
		.catch((error: any) => error)
}

const createSpecificationsApi = (id: number, data: ISpetification) =>
	ncsApp({
		method: 'POST',
		url: `/projects/${id}/specifications`,
		data: JSON.stringify(data),
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const deleteSpecificationsApi = (id: number, projectId: number) =>
	ncsApp({
		method: 'DELETE',
		url: `/projects/${projectId}/specifications/${id}`,
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

const updateSpecificationsApi = (projectId: number, data: ISpetification) =>
	ncsApp({
		method: 'PUT',
		url: `/projects/${projectId}/specifications/${data.id}`,
		data: JSON.stringify(data),
	})
		.then((res: any) => res.data)
		.catch((error: any) => error)

export {
	domain,
	LOCATION_GIPRO,
	modeURL,
	fetchHeaders,
	authorizeApi,
	authApp,
	ncsApp,
	logoutApi,
	getProjectsApi,
	createProjectApi,
	selectProjectApi,
	deleteProjectApi,
	updateProjectApi,
	searchProjectsApi,
	updateFilesToProjectApi,
	deleteFilesApi,
	downloadFileApi,
	getRmApi,
	getOptionsApi,
	getSpecificationsApi,
	createSpecificationsApi,
	deleteSpecificationsApi,
	updateSpecificationsApi,
}
